const Helpers = require('./urlHelpers');

/**
 * Generates the full service url based on stack and the deeplink
 *
 * @param {string} stack
 * @param {string} deepLink
 *
 * @return {string}
 */
const GenerateUrl = function (stack = {}, deepLink) {
	// handle being passed a URL or a stack value
	const { url, stackKey } = stack;
	const patternLocalhost = /^https?:\/\/localhost/;

	if (deepLink === 'contentgenerator/' && stackKey) {
		// Temporary, throwaway code to support CG/Content Generation
		return Helpers.CreateUrlFromStackKey(stackKey, deepLink, 'tags-service');
	} else if (url) {
		let key;

		if (url.indexOf('SSO.aspx') !== -1) {
			key = Helpers.GetStackFromUrl(url);
		}
		if (url.match(patternLocalhost)) {
			key = 'localhost';
		}

		if (key) {
			return Helpers.CreateUrlFromStackKey(key, deepLink);
		}

		const v2URL = new URL(Helpers.ParseV2Url(url));
		v2URL.pathname = deepLink;
		return v2URL.toString();
	} else if (stackKey) {
		return Helpers.CreateUrlFromStackKey(stackKey, deepLink);
	} else {
		return Helpers.CreateUrlFromStackKey(stack, deepLink);
	}
};

/**
 * Generates the full secure preview url based on the stack and local storage
 *
 * @param {string} stack
 *
 * @return {string}
 */
const GeneratePreviewUrl = function (stack) {
	const localstorageStack = Helpers.GetLocalStorageValue('contentbuilder_service_preview');
	let _stack;

	if (localstorageStack) {
		_stack = localstorageStack;
	} else if (stack.url) {
		_stack = Helpers.GetStackFromUrl(stack.url);
	} else if (stack.stackKey) {
		_stack = stack.stackKey.toLowerCase();
	} else {
		_stack = stack.toLowerCase();
	}

	_stack = Helpers.stackMap[_stack] || _stack;

	if (_stack === 'localhost') {
		return `http://localhost:8080`;
	}

	const falconNonProdPattern = /^(\d{3}).(dev|test|stage)$/i;
	const falconProdPattern = /^(\d{3})$/i;
	const isNonProdFalcon = _stack.match(falconNonProdPattern);
	const isProdFalcon = _stack.match(falconProdPattern);

	if (isProdFalcon) {
		return `https://user-content.${_stack}.sfmc-content.com/`;
	} else if (isNonProdFalcon) {
		return `https://user-content.${_stack}.sfmc-content${isNonProdFalcon[2]}.com/`;
	}

	// QA1S4 and QA3S3 should load preview from sfmc-content.
	// QA1S2 and QA2S2 should load preview from marketingcloudapps.com
	const stackHasSfmcContent = ['qa1s2', 'qa2s1', 'qa3s1', 'qa2s2'].indexOf(_stack) === -1;

	const localstorageDomain = Helpers.GetLocalStorageValue('contentbuilder_service_preview_domain');
	const domain = localstorageDomain === 'true' || stackHasSfmcContent ? 'sfmc-content.com' : 'marketingcloudapps.com';

	return `https://user-content.${_stack}.${domain}/`;
};

module.exports = {
	GenerateUrl,
	GeneratePreviewUrl
};
