const stackMap = {
	qa1s3: 'qa1s3',
	's3.qa1': 'qa1s3',
	qa3s3: 'qa3s3',
	's3.qa3': 'qa3s3',
	qa1s4: 'qa1s4',
	's4.qa1': 'qa1s4',
	qa3s4: 'qa3s4',
	's4.qa3': 'qa3s4',
	boa: 's5'
};

const falconStackMap = {
	'101.dev': '101.dev',
	dev2s101: '101.dev',
	'201.test': '201.test',
	test201: '201.test',
	stage300: '300.stage',
	'300.stage': '300.stage'
};

const urlMapFalcon = (stack, service) => {
	let falconUrl;
	// Falcon is "mj-tags" / 1P is "tags-service"
	if (service === 'tags-service') service = 'tags';
	switch (stack) {
		case 'dev2s101':
		case '101.dev':
			falconUrl = `https://mj-${service}.101.dev.marketingclouddevapps.com`;
			break;
		case 'test201':
		case '201.test':
			falconUrl = `https://mj-${service}.201.test.marketingcloudtestapps.com`;
			break;
		case 'stage300':
		case '300.stage':
			falconUrl = `https://mj-${service}.300.stage.marketingcloudstageapps.com`;
			break;
	}

	return falconUrl;
};

/**
 * Gets the url from localstorage for the service
 *
 * @return {?string}
 */
const GetLocalStorageValue = function (key) {
	try {
		const value = localStorage.getItem(key);
		if (value) {
			// eslint-disable-next-line no-console
			console.log(`Found stack override for ${key}, using ${value}`);
			return value;
		}
	} catch (error) {
		return null;
	}
};

const CreateUrlFromStackKey = function (
	stack,
	deepLink,
	service = 'content-builder'
) {
	const localstorageStack = GetLocalStorageValue(
		`contentbuilder_service_${deepLink}`
	);
	const _stack = localstorageStack || stack.toLowerCase();

	if (stackMap[_stack]) {
		// 1P Dev Stacks & BOA
		return `https://${service}.${stackMap[_stack]}.marketingcloudapps.com/${deepLink}`;
	} else if (falconStackMap[_stack]) {
		// Falcon Dev Stacks
		return `${urlMapFalcon(falconStackMap[_stack], service)}/${deepLink}`;
	} else if (Number.isInteger(Number(_stack))) {
		// Falcon Production Stacks: 40{x}
		if (service === 'tags-service') service = 'tags';
		return `https://mj-${service}.${_stack}.marketingcloudapps.com/${deepLink}`;
	} else if (_stack.charAt(0) === 's') {
		// 1P Production Stacks: s{x}
		return `https://${service}.${_stack}.marketingcloudapps.com/${deepLink}`;
	} else if (_stack === 'localhost') {
		// eslint-disable-next-line no-alert
		const localRoot = globalThis.prompt(
			`Enter the root URL for ${deepLink}`,
			'localhost:3000'
		);
		return `https://${localRoot}/${deepLink}`;
	} else {
		return `https://${service}.${_stack}.marketingcloudapps.com/${deepLink}`;
	}
};

// This will match any URL that has a string like:
// mc.s4.qa1.exacttarget.com
// But we are mostly looking at strings like
// https://mc.s4.qa1.exacttarget.com/cloud/tools/SSO.aspx?appId=2b5b916d-2ee0-4c87-ac35-a2f6475f822e&env=default&legacy=1&sk=QA1S4
const GetStackFromUrl = function (url) {
	const pattern1p = /(?:mc|members)\.(.+)\.exacttarget\.com/;
	const patternFalcon =
		/\/\/(?:[^.]+)\.(.+)\.(?:exacttarget|marketingcloud(?:dev|test|stage)?apps)\.com/i;
	const patternLocalhost = /^https?:\/\/localhost/;

	const match1P = url.match(pattern1p);
	if (match1P) {
		return match1P[1];
	}

	const matchFalcon = url.match(patternFalcon);
	if (matchFalcon) {
		return matchFalcon[1];
	}

	const matchLocalhost = url.match(patternLocalhost);
	if (matchLocalhost) {
		return 'localhost';
	}

	// s1 doesn't have the stack in the URL so fall back to s1 if not found
	return 's1';
};

const ParseV2Url = function (url) {
	if (url.indexOf('login') != -1) {
		const parsed = url.substring(0, url.indexOf('login'));
		return parsed;
	} else {
		return url;
	}
};

module.exports = {
	GetLocalStorageValue,
	CreateUrlFromStackKey,
	GetStackFromUrl,
	ParseV2Url,
	stackMap
};
